import { cn } from '~/lib/utils';

type WysiwigViewerProps = {
  html: string;
  className?: string;
};

export default function WysiwigViewer({ html, className }: WysiwigViewerProps) {
  return (
    <div
      className={cn('prose', className)}
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  );
}
